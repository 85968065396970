import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import map from "lodash/map";
import cn from "classnames";
import { withRouter } from "react-router";
import { validateLogin } from "utils/validations";
import { goToRoute } from "helpers/react-router";
import DqButton from "components/common/elements/DqButton";
import DqInput from "components/common/forms/DqInput";
import SocialSignOn from "components/auth/SocialSignOn";
import DqLoading from "components/common/feedback/DqLoading";
import ReCAPTCHA from "components/auth/Recaptcha";
import withJumplinks from "components/auth/Jumplinks/AuthJumplinks";
import { window_location } from "helpers/window_location";

import {
  selectors as user_selectors,
  actions as user_actions,
} from "redux/modules/user_info";
import EventsTracker from "helpers/events_tracker";
import { ONBOARDING_SHOWN_BUT_NOT_COMPLETED } from "helpers/constants";
import { skipRecaptcha, checkRecaptcha } from "helpers/checkRecaptcha";

export const selectors = user_selectors;
export const actions = {
  ...user_actions,
};

const recaptchaRef = React.createRef();

const mapStateToProps = createStructuredSelector(selectors);

const ANALYTICS_SIGNUP = "accounts-signup-success-frontend";
const TEAM_ADMIN_POSTLOGIN_URL = "/team/members";

export class Login extends React.PureComponent {
  static propTypes = {
    // from redux
    forgot_sent: PropTypes.bool.isRequired,
    password_reset: PropTypes.bool.isRequired,

    login_errors: PropTypes.object.isRequired,
    displayMFARequest: PropTypes.bool.isRequired,

    login: PropTypes.func.isRequired,
    setReferral: PropTypes.func.isRequired,
    get_social_token_login: PropTypes.func.isRequired,
    set_login_error: PropTypes.func.isRequired,
    set_forgot_sent: PropTypes.func.isRequired,
    setSkipOnboarding: PropTypes.func.isRequired,
    storeAuthData: PropTypes.func.isRequired,
    setDisplayCourseContext: PropTypes.func.isRequired,

    // user info
    is_logged_in: PropTypes.bool,
    hasTeamAdminAccess: PropTypes.bool,
    onboarding_status: PropTypes.string,
    display_onboarding: PropTypes.bool,
    switchMode: PropTypes.func,
    authData: PropTypes.shape({
      username: PropTypes.string,
      password: PropTypes.string,
    }).isRequired,

    // passed in
    signupReferralCode: PropTypes.string,
    signupReferralEmail: PropTypes.string,
    hide_forgot_password_link: PropTypes.bool,
    refresh_user: PropTypes.func.isRequired,

    // from with jumplinks component
    targetUrls: PropTypes.shape({
      targetPath: PropTypes.string,
      targetCourse: PropTypes.string,
      targetLesson: PropTypes.string,
      targetVersion: PropTypes.string,
      targetUrl: PropTypes.string,
      postSignupUrl: PropTypes.string,
      postLoginUrl: PropTypes.string,
    }),
    targetSlugs: PropTypes.shape({
      path: PropTypes.string,
      course: PropTypes.string,
      lesson: PropTypes.string,
    }),
    skipSource: PropTypes.string,

    // router
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,

    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    signupReferralCode: "",
    signupReferralEmail: "",
    hide_forgot_password_link: false,
    switchMode: null,

    is_logged_in: false,
    hasTeamAdminAccess: false,
    onboarding_status: "",
    display_onboarding: "",

    // From jumplinks wrapped component
    targetUrls: {
      targetPath: "",
      targetVersion: "",
      targetCourse: "",
      targetLesson: "",
      targetUrl: "",
      postLoginUrl: "",
      postSignupUrl: "",
    },
    targetSlugs: {
      path: "",
      course: "",
      lesson: "",
    },

    skipSource: "",
  };

  static hasAnError = nextProps => !isEmpty(nextProps.login_errors);

  static renderResetSuccess = () => (
    <p>
      Your password has been reset!
      <br />
      Try logging in with your new password.
    </p>
  );

  static renderResetSent = () => (
    <p>
      An email has been sent to you. Click the link in the email to reset your
      password.
    </p>
  );

  constructor() {
    super();
    this.state = {
      submitting: false,
      email: "",
      password: "",
      mfaToken: "",
      loadReCAPTCHA: false,
    };
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps) {
    // A workaround for component re-mounting
    if (this.props.displayMFARequest && !prevProps.displayMFARequest)
      this.props.storeAuthData({
        email: this.state.email,
        password: this.state.password,
      });

    if (Login.hasAnError(this.props)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ submitting: false });
      if (checkRecaptcha(recaptchaRef)) {
        recaptchaRef.current.reset();
      }
    }
    if (this.props.forgot_sent) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ submitting: false });
    }
    if (this.props.password_reset && !prevProps.password_reset) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        password: "",
        submitting: false,
      });
    }

    if (!window_location.isIframe() && this.props.is_logged_in) {
      this.redirectLoggedInUser();
    }
  }

  componentWillUnmount() {
    if (this.props.forgot_sent) {
      this.props.set_forgot_sent(false);
    }
  }

  getPostLoginUrl = data => {
    let { postLoginUrl } = this.props.targetUrls;
    if (
      (this.props.hasTeamAdminAccess || data?.profile?.has_team_admin_access) &&
      !postLoginUrl
    )
      postLoginUrl = TEAM_ADMIN_POSTLOGIN_URL;

    // Redirect users to the last page they visited in the app
    // if any, exclude login page and target urls
    if (
      window.location.pathname &&
      !window.location.pathname.includes("/login") &&
      postLoginUrl.indexOf(window.location.pathname) === -1
    ) {
      postLoginUrl = window.location.pathname;
    }

    return postLoginUrl;
  };

  redirectLoggedInUser = () => {
    const { targetPath, targetCourse, targetLesson } = this.props.targetUrls;
    const postLoginUrl = this.getPostLoginUrl();

    const { onboarding_status, display_onboarding, skipSource } = this.props;
    const onboardingSkipReason = targetPath || targetCourse || targetLesson;

    const postLoginGoto = () => goToRoute(this.props.history, postLoginUrl);

    // If user is redirected to a course, display course context modal
    if (targetCourse) this.props.setDisplayCourseContext(targetCourse);

    if (display_onboarding) {
      goToRoute(this.props.history, "/onboarding");
      return;
    }

    // If onboarding hasn't been completed and has a skip reason, skip it
    if (
      onboarding_status === ONBOARDING_SHOWN_BUT_NOT_COMPLETED &&
      onboardingSkipReason
    ) {
      this.props
        .setSkipOnboarding(
          onboardingSkipReason,
          window_location.isIframe(),
          skipSource,
        )
        .then(() => postLoginGoto());
    } else postLoginGoto();
  };

  getCredentials = () => {
    let credentials = { ...pick(this.state, ["email", "password"]) };
    if (this.props.displayMFARequest) {
      credentials = { ...pick(this.props.authData, ["email", "password"]) };
      credentials.mfa_token = this.state.mfaToken;
    }

    return credentials;
  };

  makeSubmitCall = (recaptchaToken = "") => {
    const {
      targetPath,
      targetVersion,
      targetCourse,
      targetLesson,
    } = this.props.targetUrls;

    let skipOnboarding = null;
    if (targetPath || targetCourse || targetLesson)
      skipOnboarding = {
        path: targetPath,
        version: targetVersion,
        course: targetCourse,
        lesson: targetLesson,
      };

    const credentials = {
      ...this.getCredentials(),
      recaptchaToken,
    };

    this.props.login(credentials, skipOnboarding).then(data => {
      // when password is wrong, data value is `undefined`;
      // TODO: probably we should fix whatever is going wrong here,
      // but for now I (Darla) am just working around it in order to get
      // login event tracking working as expected
      const loginFailure =
        !data || (data && data.type && data.type === "user_info/login_errors");
      if (loginFailure) {
        return;
      }
      const postLoginUrl = this.getPostLoginUrl(data);

      goToRoute(this.props.history, postLoginUrl, {
        source: "login_redirect",
      });
    });
  };

  // eslint-disable-next-line complexity
  submit = event => {
    event.preventDefault();

    const { email, password } = this.state;
    const validation = validateLogin({ email, password });
    let validationErrors = "";
    this.props.set_login_error({});

    if (!isEmpty(validation.error) && !this.props.displayMFARequest) {
      validation.error.details.forEach(item => {
        validationErrors = `${validationErrors} ${item.message}`;
      });

      this.reportFailure(validationErrors);

      if (checkRecaptcha(recaptchaRef)) {
        recaptchaRef.current.reset();
      }

      return;
    }

    this.setState({ submitting: true });
    if (skipRecaptcha(this.props.displayMFARequest)) {
      // ignore recaptcha
      this.makeSubmitCall();
    } else {
      try {
        // initiate recaptcha validation
        recaptchaRef.current.execute();
      } catch (e) {
        console.error("error loading grecaptcha");
      }
    }
  };

  reportFailure = message => {
    this.props.set_login_error({ general: message });
  };

  socialLoginFailure = (service, message) => {
    this.reportFailure(message);
  };

  handleOnChange = event => {
    const currentState = this.state;

    this.setState({
      ...currentState,
      [event.target.name]: event.target.value,
    });

    // Load ReCAPTCHA upon user interaction with form
    this.loadRecaptchaComponent();
  };

  loadRecaptchaComponent = () => {
    const { loadReCAPTCHA } = this.state;

    // To improve lighthouse scores,
    // load ReCAPTCHA once user interacts with form
    if (!loadReCAPTCHA) {
      this.setState({
        loadReCAPTCHA: true,
      });
    }
  };

  setReferral = () => {
    if (this.props.signupReferralCode) {
      this.props.setReferral(
        this.props.signupReferralCode,
        this.props.signupReferralEmail,
      );
    }
  };

  socialLoginSuccess = (service, isNew) => {
    const {
      targetPath,
      targetVersion,
      targetCourse,
      targetLesson,
    } = this.props.targetUrls;

    let skipOnboarding = null;
    if (targetPath || targetCourse || targetLesson)
      skipOnboarding = {
        path: targetPath,
        version: targetVersion,
        course: targetCourse,
        lesson: targetLesson,
      };

    this.props.get_social_token_login(skipOnboarding).then(() => {
      this.props.refresh_user().then(() => {
        const postLoginUrl = this.getPostLoginUrl();
        if (isNew && postLoginUrl.indexOf("/m/") === -1) {
          // If the user doesn't sign up from a lesson page, disable the welcome modal
          localStorage.setItem("welcomeDialogDisplayed", true);
        }
        this.setReferral();
        if (isNew) {
          EventsTracker.track(
            "account_signup",
            {
              status: "success",
              signup_type: service,
              source_url: this.props.location.pathname,
            },
            {
              version: 3,
            },
          );
          // Deprecated / old event (keeping until data migration is complete)
          EventsTracker.track(ANALYTICS_SIGNUP, {
            service,
          });
        }
        goToRoute(this.props.history, postLoginUrl);
      });
    });
  };

  /**
   * recaptcha data callback
   * executed when user submits a successful response
   * g-recaptcha-response token is passed in as an argument
   * @param {string} recaptchaToken
   */
  onRecaptchaCallback = recaptchaToken => {
    this.makeSubmitCall(recaptchaToken);
  };

  /**
   * recaptcha error callback
   * executed when reCAPTCHA encounters an error (usually network connectivity)
   * and cannot continue until connectivity is restored
   */
  onRecaptchaErroredCallback = () => {
    this.reportFailure(
      "There was a network error. Check your connection and try again.",
    );
  };

  renderGeneralErrors = () => {
    const errors = this.props.login_errors;

    if (isEmpty(errors)) {
      return null;
    }

    return (
      <div
        className="dq-general-errors dq-text-red dq-bg-red-100 dq-border-red dq-p-4 dq-block dq-input-block dq-animate-appear"
        data-test-selector="error-notification"
      >
        {map(errors, (e, key) => (
          <div key={key} className="dq-text-center">
            {e}
          </div>
        ))}
      </div>
    );
  };

  renderEmailField = () => (
    <DqInput
      label="Email"
      id="email"
      labelClassName="dq-text-lg"
      data-test-selector="email"
      type="email"
      placeholder="Email"
      disabled={this.state.submitting}
      value={this.state.email}
      onChange={this.handleOnChange}
    />
  );

  renderPasswordField = () => (
    <DqInput
      label="Password"
      id="password"
      labelClassName="dq-text-lg"
      data-test-selector="password"
      type="password"
      placeholder="Password"
      disabled={this.state.submitting}
      value={this.state.password}
      onChange={this.handleOnChange}
    />
  );

  renderTokenField = () => (
    <DqInput
      label="Code"
      id="mfaToken"
      labelClassName="dq-text-lg"
      data-test-selector="token"
      type="password"
      placeholder="--- ---"
      disabled={this.state.submitting}
      value={this.state.mfaToken}
      onChange={this.handleOnChange}
    />
  );

  renderSubmitButton = textArg => (
    <DqButton
      data-test-selector="submit"
      type="submit"
      className="dq-w-full"
      size="large"
      loading={this.state.submitting}
      text={textArg}
    />
  );

  renderLoginHeader = () => (
    <div className="dq-flex dq-flex-col">
      <p className="dq-w-full dq-text-center dq-m-0 dq-text-3xl sm:dq-text-xl dq-font-bold">
        Log in to Dataquest
      </p>
      <div className="dq-flex dq-justify-end dq-mt-2">
        <p className="dq-text-sm dq-leading-tight">
          No account?{" "}
          {this.props.switchMode ? (
            <DqButton
              text="Create one"
              onClick={this.props.switchMode}
              variant="text"
              size="small"
            />
          ) : (
            <DqButton
              text="Create one"
              to={`/signup${this.props.location.search || ""}`}
              data-test-selector="LoginOrSignupSwitch"
              variant="text"
              size="small"
            />
          )}
        </p>
      </div>
    </div>
  );

  renderLoginFields = () => (
    <form
      onSubmit={this.submit}
      id="authForm"
      className="dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto"
    >
      {this.renderGeneralErrors()}
      {this.props.password_reset && Login.renderResetSuccess()}
      {this.renderLoginHeader()}
      {this.renderEmailField()}
      {this.renderPasswordField()}
      {this.renderForgot()}
      <div className="dq-mt-2">{this.renderSubmitButton("Log in")}</div>
      <p className="dq-text-lg dq-my-2 dq-text-center">
        Or log in using an existing account
      </p>
      <SocialSignOn
        onSuccess={this.socialLoginSuccess}
        onFailure={this.socialLoginFailure}
      />
    </form>
  );

  renderMFAFields = () => (
    <form
      onSubmit={this.submit}
      className="dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto"
    >
      <h2 className="dq-text-3xl dq-mb-4">2-Step Verification</h2>
      <p>
        Please launch Google Authenticator application and enter the displayed
        code.
      </p>
      {this.renderGeneralErrors()}
      {this.renderTokenField()}
      {this.renderSubmitButton("Verify")}
    </form>
  );

  renderForgot = () => {
    if (this.props.hide_forgot_password_link) {
      return null;
    }

    return (
      <div>
        <DqButton
          to="reset-password"
          variant="text"
          text="Forgot your password?"
          disabled={this.state.submitting}
        />
      </div>
    );
  };

  render() {
    if (Cookies.getJSON("token")) return <DqLoading fullScreen />;
    const { loadReCAPTCHA } = this.state;

    return (
      <div
        className={cn(
          "dq-login dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto dq-px-5 sm:dq-px-0",
        )}
      >
        {this.props.displayMFARequest
          ? this.renderMFAFields()
          : this.renderLoginFields()}
        {loadReCAPTCHA && (
          <ReCAPTCHA
            recaptchaRef={recaptchaRef}
            handleOnChange={this.onRecaptchaCallback}
            handleOnErrored={this.onRecaptchaErroredCallback}
          />
        )}
      </div>
    );
  }
}

export default withJumplinks(
  withRouter(
    connect(
      mapStateToProps,
      actions,
    )(Login),
  ),
);
